import styled from 'styled-components'
import Nav from '../components/Nav'

const Wrapper = styled.div`
  background-color: #1a1a1a;
  padding: 15px 0;
  position: relative;

  @media (min-width: 720px) {
    padding: 20px 0;
  }
`

const Logo = styled.div`
  display: inline-flex;
  padding-right: 28px;
  margin-right: 32px;
  align-items: center;

  a {
    display: inline-block;
  }

  img {
    height: 24px;
    display: block;
  }

  @media (min-width: 720px) {
    border-right: 1px solid #444;

    img {
      height: 32px;
    }
  }
`

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 720px) {
    justify-content: initial;
  }
`

const Header = () => {
  return (
    <Wrapper>
      <div className="innerWrapper">
      <NavWrapper>
        <Logo>
          <a href="https://www.klustr.net"><img src="/assets/images/klustr_logo.png" alt="" /></a>
        </Logo>
        <Nav />
      </NavWrapper>
      </div>
    </Wrapper>
  )
}

export default Header