export const ReleasesList = [
  {
    title: 'Mega Man 10: Reimagined',
    id: 'mega-man-10-reimagined',
    alias: 'E-Tank',
    date: '2022-05-01',
    type: 'Album',
    length: '73:14',
    cover_front: 'E-Tank_-_Mega_Man_10_Reimagined_(Front).png',
    cover_back: 'E-Tank_-_Mega_Man_10_Reimagined_(Back).png',
    tracks: [
      'Threat from Outer Space!! (Title)',
      'The Robot Masters (Stage select)',
      'Stage start',
      'Treacherous Depths (Pump Man)',
      'Counting Cybersheep (Sheep Man)',
      'Chilled To The Core (Chill Man)',
      'Go For The Score! (Strike Man)',
      'Metal Against Metal (Boss Theme)',
      'Valiant Clash (Blade Man)',
      'Red Hot Flare (Solar Man)',
      'Blazing Battle (Commando Man)',
      'Maximum Speed (Nitro Man)',
      'Silent Rain (Wily Stage 1)',
      'Dr. Wily Stage Boss',
      'Abandoned Memory (Wily Stage 1)',
      'Sinister Machinery (Wily Stage 2)',
      'Journey To The Top (Wily Stage 3)',
      'Network Of Trials (Wily Stage 4)',
      'The Skull Battleship (Wily Machine)',
      'Final Destination (Wily Stage 5)',
      'Moment Of Truth (Wily Capsule)',
      'A Brighter Tomorrow (Staff Roll)',
      'Heart of Enker (Special Stage 1)',
      'Get the Punk Out (Special Stage 2)',
      'Farewell to Ballade (Special Stage 3)',
      'Endless Struggle (Endless Mode)',
    ],
    url: 'https://soundcloud.com/soltype/sets/e-tank-mega-man-10-reimagined',
    link_label: 'Listen',
    notes: 'This project started in early 2010 and was only partially completed the following year. In late 2021 and early 2022 new arrangements were produced to cover frequently requested songs from the game.'
  }
]