import styled from 'styled-components'
import NewsPost from '../components/NewsPost'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 920px) {
    flex-direction: row;
  }
`
const Posts = styled.div`
  width: 100%;
  flex-shrink: 0;

  @media (min-width: 920px) {
    width: 68%;
    margin-right: 12px;
    margin-bottom: 0;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  > * {
    margin-bottom: 12px;
  }
`
const Info = styled.div`
  color: #cbcbcb;
  align-self: flex-start;
  position: sticky;
  top: 0;
`

const InfoBox = styled.div`
  background-color: #1a1a1a;
  padding: 18px;
  line-height: 1.55em;
  font-size: 14px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 12px;

  @media (min-width: 720px) {
    padding: 26px 22px;
  }

  h3 {
    margin-bottom: 7px;
  }

  h3:first-child {
    margin-top: 0;
  }

  p {
    margin-bottom: 1.8em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Links = styled.ul`
  list-style-type: none;
  margin-top: -3px;
  padding-left: 0;

  li {
    border-bottom: 1px solid #333;
    padding: 5px 0;

    &:last-child {
      border-bottom: 0;
    }
  }
`

const Home = () => {
  return (
    <Wrapper>
      <Posts>
        <NewsPost header='"Ambel" reissue and Doom tribute album' date="2023-08-26">
          <p>
            Since last year I have been reworking some of my older albums/EPs. This is a restoration project of sorts, as many of the
            tracks went missing from the internet for years and I unfortunately lost the original uncompressed files.
            This project has allowed me to examine the old material with more experienced ears and add some new layers and flourishes
            to improve the songs and hopefully their fidelity as well. Recently I finished work on <a href="/music/ambel">"Ambel"</a> which was originally done in
            2005, have a listen!
          </p>
          <p>
            Also, I'm busy putting together an album covering songs from <strong>Doom (1993)</strong> and <strong>Doom II (1994)</strong>. It'll have about 10-12
            tracks in total, with a mix of electronica and industrial rock (the most guitar-heavy endeavour I've taken on so far, probably).
            Looking forward to having it finished in early 2024.
          </p>
        </NewsPost>
        <NewsPost header='Welcome to the new and improved website' date="2023-02-08">
          <p>
            Long time no see! I thought it was about time this place got a facelift. Last time there was an update was...
            March 1, 2011. Wow, time does fly. But don't think I haven't been busy. Those of you who keep up with me on YouTube
            already know I've been posting game music covers for a while now. I've also released an album, <a href="/music/lifeshades">"Lifeshades"</a> along with an EP
            called <a href="/music/mending-days">"Mending Days"</a>.
          </p>
          <p>
            The music page now offers nearly everything I've done since 2002, previously only a few of my releases were available
            for listening. You may also notice I've updated the artwork for some of them and switched pseudonyms on a few. The reason
            for doing this is because I felt that I had my releases spread across far too many pseudonyms which just made things
            needlessly complicated. So from now on I'm only using <strong>Cvel</strong> for my fully electronic music and <strong>Soltype</strong> for my electronic/rock blend
            of music. I feel like these represent two different directions that my music has branched into and will over time
            probably become even more different from eachother.
          </p>
          <p>
            As for future plans, I am currently working on a new album. That's all I can say for now, as it's a long way from being completed.
            But I'm excited about it and hope to share more later.
          </p>
        </NewsPost>
      </Posts>
      <Info>
        <InfoBox>
          <h3>A quick introduction</h3>
          <p>I'm Tobias Franzén and this is an online showcase of my works. I make a variety of music ranging from electronica, rock, downtempo, and ambient.
          I also rearrange music from video games!<br /><br /><Link to="/about">Read more about me here &raquo;</Link></p>
        </InfoBox>
        <InfoBox>
          <h3>Related links</h3>
          <Links>
            <li><a href="https://soltype.bandcamp.com/" target="_blank" rel="noreferrer">Soltype - Bandcamp</a></li>
            <li><a href="https://cvel.bandcamp.com/">Cvel - Bandcamp</a></li>
            <li><a href="https://www.youtube.com/c/Gencoil">VGM Arrangements - YouTube</a></li>
          </Links>
        </InfoBox>
      </Info>
    </Wrapper>
  )
}

export default Home