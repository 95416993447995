import React, { useState } from 'react'

import styled, { keyframes } from 'styled-components'
import LinkButton from '../components/LinkButton'
import Button from '../components/Button'
import ExternalLink from '../font-awesome/ExternalLink'

const fadein = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`

const Wrapper = styled.div`
  line-height: 22px;
  text-decoration: none;
  color: #fff;
  display: flex;
  margin-top: 15px;
  flex-direction: column;

  @media (min-width: 720px) {
    flex-direction: row;
  }

  animation: ${fadein} 0.5s;
`

const ReleaseCovers = styled.div`
  width: 100%;

  @media (min-width: 720px) {
    width: 486px;
    max-width: 48.215%;
    flex-shrink: 0;
  }
`

const ReleaseCover = styled.img`
  width: 100%;
  min-height: 100px;
  display: block;
  align-self: flex-start;
  background-color: #000;
`

const ReleaseInfo = styled.div`
  margin-top: 12px;
  flex-grow: 1;

  @media (min-width: 720px) {
    align-self: flex-start;
    margin-top: 0;
    margin-left: 12px;
  }
`

const ReleaseDetails = styled.div`
  background-color: #222;
  padding: 18px;
  flex-grow: 1;

  @media (min-width: 720px) {
    padding: 24px 24px 26px 24px;
  }
`

const ReleaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`

const ReleaseAlias = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`

const ReleaseTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 15px;

  @media (min-width: 720px) {
    margin-bottom: 5px;
    font-size: 24px;
  }
`

const ReleaseSpecs = styled.div`
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  margin: 20px 0;
  padding: 15px 0;

  table {
    border-collapse: collapse;
  }

  td {
    padding: 0;
  }

  td:nth-child(1) {
    padding-right: 20px;
  }
`

const TrackList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  li {
    display: flex;
    margin-bottom: 2px;

    @media (min-width: 720px) {
      margin-bottom: 3px;
    }
  }
`

const TrackNumber = styled.span`
  color: #aaa;
  width: 22px;
  padding-right: 12px;
  flex-shrink: 0;
  text-align: center;
`

const Fade = styled.div`
  background: linear-gradient(0deg, rgba(34,34,34,1) 0%, rgba(255,255,255,0) 100%);
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
`

const ShowAll = styled.div`
  margin-top: 12px;
`

const Notes = styled.div`
  margin-top: 12px;
  background-color: rgba(0,0,0,0.30);
  padding: 18px;
  line-height: 1.65em;
  font-size: 14px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (min-width: 720px) {
    padding: 20px 24px 24px 24px;
  }
`

const Sample = styled.div`
  margin-top: 18px;

  > iframe {
    display: block;
  }
`

const Release = ({
  title,
  alias,
  type,
  length,
  date,
  url,
  bandcamp_embed,
  soundcloud_embed,
  link_label = 'Listen / buy',
  tracks = [],
  cover_front,
  cover_back,
  notes,
}) => {

  const [currentCover, setCurrentCover] = useState('front')
  const [showAllTracks, setShowAllTracks] = useState(false)

  const enableShowAll = tracks && tracks.length > 18
  const showAllLabel = !showAllTracks ? `Show all ${tracks?.length} tracks` : 'Show less'

  const setCover = (side) => {
    setCurrentCover(side)
  }

  const toggleTracks = () => {
    setShowAllTracks(!showAllTracks)
  }

  const trackList = showAllTracks ? tracks : tracks.slice(0,18)

  return (
    <>
      <Wrapper>
        <ReleaseCovers>
          <div className={`flipbox ${currentCover}`}>
            <div className="flipbox-inner">
              {cover_front && (
                <ReleaseCover src={`${process.env.PUBLIC_URL}/assets/images/releases/${cover_front}`} className="flipbox-front" alt={`${alias} - ${title} (front cover)`} />
              )}
              {cover_back && (
                <ReleaseCover src={`${process.env.PUBLIC_URL}/assets/images/releases/${cover_back}`} className="flipbox-back" alt={`${alias} - ${title} (back cover)`} />
              )}
            </div>
          </div>
          <div className="btnGroup">
            {cover_front && (
              <Button handleClick={() => setCover('front')} className={currentCover === 'front' ? 'active' : ''}>Front</Button>
            )}
            {cover_back && (
              <Button handleClick={() => setCover('back')} className={currentCover === 'back' ? 'active' : ''}>Back</Button>
            )}
          </div>
        </ReleaseCovers>
        <ReleaseInfo>
          <ReleaseDetails>
            <ReleaseHeader>
              <div>
                <ReleaseAlias>{alias}</ReleaseAlias>
                <ReleaseTitle>{title}</ReleaseTitle>
              </div>
              <div>
                {url &&
                  <LinkButton to={url} target="_blank">{link_label} &nbsp;<ExternalLink /></LinkButton>
                }
                {!url && link_label &&
                  <LinkButton to="#" className="disabled">{link_label}</LinkButton>
                }
              </div>
            </ReleaseHeader>
            <ReleaseSpecs>
              <table>
                <tbody>
                  <tr>
                    <td><strong>Format:</strong> </td><td>{type}</td>
                  </tr>
                  <tr>
                    <td><strong>Length:</strong> </td><td>{length || '-'}</td>
                  </tr>
                  <tr>
                    <td><strong>Released:</strong> </td><td>{date}</td>
                  </tr>
                </tbody>
              </table>
            </ReleaseSpecs>

            {tracks && tracks.length > 0 &&
              <TrackList>
                {trackList.map((track, index) => (
                  <li key={track}><TrackNumber>{index + 1} </TrackNumber><span>{track}</span></li>
                ))}
                {enableShowAll && !showAllTracks &&
                  <Fade />
                }
              </TrackList>
            }

            {enableShowAll &&
              <ShowAll>
                <Button handleClick={() => toggleTracks()}>{showAllLabel}</Button>
              </ShowAll>
            }
          </ReleaseDetails>

          {notes &&
            <Notes>
              <strong style={{ display: 'inline-block', marginBottom: '4px' }}>Notes & comments:</strong><br />
              {notes}
            </Notes>
          }

          {bandcamp_embed && (
            <Sample>
              <strong>Sample track</strong>
              <iframe
                style={{ border: 0, width: '100%', height: '120px', marginTop: '12px' }}
                src={bandcamp_embed}
                seamless
              >
                <a href={url}>{title} by {alias}</a>
              </iframe>
            </Sample>
          )}

          {soundcloud_embed && (
            <Sample>
              <strong>Sample track</strong>
              <iframe
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                style={{ marginTop: '12px' }}
                src={soundcloud_embed.iframe_url}>
              </iframe>
              <div
                style={{ fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: 100 }}>
                <a href={soundcloud_embed.page_url} title="tobias.franzen" target="_blank" style={{ color: '#cccccc', textDecoration: 'none' }}>tobias.franzen</a> · <a href={soundcloud_embed.track_url} title={soundcloud_embed.track_name} target="_blank" style={{ color: '#cccccc', textDecoration: 'none' }}>{soundcloud_embed.track_name}</a></div>
            </Sample>
          )}
        </ReleaseInfo>
      </Wrapper>
    </>
  )
}

export default Release