import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #aaa;
  min-height: 0;
  flex-shrink: 0;
  height: 240px;
  background-image: url(/assets/images/panoramas/path-shifter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 15px;

  @media (min-width: 720px) {
    height: 400px;
    margin-bottom: 30px;
  }

  .innerWrapper {
    position: relative;
    height: 100%;
  }
`

const TextHolder = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: inherit;
  padding-right: inherit;
  padding-bottom: 20px;
  text-align: right;

  @media (min-width: 720px) {
    padding-bottom: 25px;
  }
`

const PreHeader = styled.div`
  font-size: 15px;
  letter-spacing: -1px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #fff;
  background-color: #222;
  padding: 3px 8px 4px 8px;
  display: inline-block;

  @media (min-width: 720px) {
    font-size: 18px;
  }
`

const Header = styled.div`
  font-size: 24px;
  letter-spacing: -2px;
  line-height: 29px;
  font-weight: bold;
  color: #fff;

  @media (min-width: 720px) {
    font-size: 31px;
    line-height: 37px;
  }

  a {
    color: inherit;
  }
`

const Fade = styled.div`
  background: linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
`

const ReleaseImage = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  align-items: center;
  margin-left: 3%;

  img {
    height: 90%;
    max-height: 300px;
    box-shadow: 0px 5px 12px rgba(0,0,0,0.3);
  }
`

const Hero = () => {
  const showFade = false

  return (
    <Wrapper>
      {showFade && <Fade />}
      <div className="innerWrapper">
        <ReleaseImage>
          <img src={`${process.env.PUBLIC_URL}/assets/images/releases/Cvel_-_Path_Shifter_(Front).png`} alt="Cvel - Path Shifter" />
        </ReleaseImage>
        <TextHolder>
          <PreHeader>Latest release</PreHeader>
          <Header><a href="/music/path-shifter">"Path Shifter" EP out now&nbsp;&nbsp;&raquo;</a></Header>
        </TextHolder>
      </div>
    </Wrapper>
  )
}

export default Hero