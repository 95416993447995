import { ReleasesList } from '../data/releases'
import ReleaseViewer from '../components/ReleaseViewer'

const Music = () => {
  return (
    <div>
      <h1>Music releases</h1>
      <ReleaseViewer releases={ReleasesList} basePath="music" backLabel="Browse more releases" />
    </div>
  )
}

export default Music