import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: rgba(25, 25, 25, 0.65); /* #222; */
  padding: 18px;
  font-size: 14px;
  line-height: 22px;
  word-spacing: 1px;

  @media (min-width: 720px) {
    padding: 24px 28px 28px 28px;
    font-size: ${props => props.fontSize}px;
    line-height: 23px;
  }

  > p:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  > h2:first-child {
    margin-top: 0;
    margin-bottom: 2px;
  }
`

const TextBox = ({children, fontSize = 15}) => {
  return (
    <Wrapper fontSize={fontSize}>
      {children}
    </Wrapper>
  )
}

export default TextBox