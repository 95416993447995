export const ReleasesList = [
  {
    title: 'Path Shifter',
    id: 'path-shifter',
    alias: 'cvel',
    date: '2022-11-21',
    type: 'EP',
    length: '24:00',
    cover_front: 'Cvel_-_Path_Shifter_(Front).png',
    cover_back: 'Cvel_-_Path_Shifter_(Back).png',
    url: 'https://cvel.bandcamp.com/album/path-shifter',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=1832057646/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=1938422256/transparent=true/',
    tracks: [
      'Oppressor',
      'Strila',
      'Anvele',
      'Quartz Res',
      'Pedll',
      'Heart Unison',
    ],
    notes: "Tracks 1 + 4 are recent recordings while other tracks were recorded many years earlier but later revised."
  },
  {
    title: 'Mending Days',
    id: 'mending-days',
    alias: 'Soltype',
    date: '2022-06-17',
    type: 'EP',
    length: '27:16',
    cover_front: 'Soltype_-_Mending_Days_(Front).png',
    cover_back: 'Soltype_-_Mending_Days_(Back).png',
    url: 'https://soltype.bandcamp.com/album/mending-days-ep',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=1926033469/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=3413681868/transparent=true/',
    tracks: [
      'Knowing You\'re Here',
      'Morning Veil',
      'Into September',
      'To Cure a Restless Mind',
      'The Ones You Miss',
      'Outlasting',
      'Night Lantern'
    ],
  },
  {
    title: 'Fuji',
    id: 'fuji',
    alias: 'Soltype',
    date: '2021-10-27',
    type: 'Single',
    length: '18:14',
    cover_front: 'Soltype_-_Fuji_(Front).png',
    cover_back: 'Soltype_-_Fuji_(Back).png',
    url: 'https://soltype.bandcamp.com/album/fuji-single',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=3565029370/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=3264271138/transparent=true/',
    tracks: [
      'Fuji (Single version)',
      'As Days Flow By',
      'Road Spirit (Highway Remix)',
      'Fuji (Night Eruption Mix)'
    ],
    notes: "Released to promote the album \"Lifeshades\".",
  },
  {
    title: 'Lifeshades',
    id: 'lifeshades',
    alias: 'Soltype',
    date: '2021-07-15',
    type: 'Album',
    length: '58:29',
    cover_front: 'Soltype_-_Lifeshades_(Front).png',
    cover_back: 'Soltype_-_Lifeshades_(Back).png',
    url: 'https://soltype.bandcamp.com/album/lifeshades',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=3991465665/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=3981059501/transparent=true/',
    tracks: [
      'Future Mindstate',
      'Newborn',
      'Fuji',
      'Early Spring',
      'Lightspeed Drifter',
      'This Exploding Nightscape',
      'Road Spirit',
      'Weightless',
      'In Peaceful Oblivion',
      'Wherever You Are Now',
      'It Was a Dream',
      'Breathe In',
      'As Long as We Live',
      'Universe',
    ],
    notes: "Additional electric guitar performance by Thomas Franzén on \"Newborn\" and \"Lightspeed Drifter\".",
  },
  {
    title: 'On A Golden Horizon',
    id: 'on-a-golden-horizon',
    alias: 'Soltype',
    date: '2011-03-01',
    type: 'Album',
    length: '41:25',
    cover_front: 'Soltype_-_On_A_Golden_Horizon_(Front).png',
    cover_back: 'Soltype_-_On_A_Golden_Horizon_(Back).png',
    url: 'https://soltype.bandcamp.com/album/on-a-golden-horizon',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=458444029/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=3880086099/transparent=true/',
    tracks: [
      'Sleepwalk',
      'Higher Up',
      'Awake',
      'Sparkles in the Air',
      'Fields',
      'Jittery Feet',
      'Inner Speaking',
      'Little Star',
      'Heatstroke',
      'Before the Moment Fades'
    ]
  },
  {
    title: 'Hibernation',
    id: 'hibernation',
    alias: 'cvel',
    date: '2009-03-20',
    type: 'Album',
    length: '47:23',
    cover_front: 'Cvel_-_Hibernation_(Front).png',
    cover_back: 'Cvel_-_Hibernation_(Back).png',
    link_label: 'Listen / Download',
    url: 'https://soundcloud.com/soltype/sets/hibernation-2009',
    soundcloud_embed: {
      iframe_url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/8719227&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true',
      page_url: 'https://soundcloud.com/soltype',
      track_url: 'https://soundcloud.com/soltype/nocturnal-living',
      track_name: 'Nocturnal Living',
    },
    tracks: [
      'Zero Celsius',
      'Lights Shut Down',
      'Ogenki',
      'Elaborate Origami',
      'Tumble',
      'Circadian Clock',
      'Homeclutter',
      'An Endless Endeavour',
      'Polyp',
      'Reflective',
      'Fill in the Blanks',
      'Primrose',
      'Nocturnal Living'
    ],
    notes: "Originally released under the alias Soltype.",
  },
  {
    title: 'Cut&Waste',
    id: 'cut-n-waste',
    alias: 'cvel',
    date: '2006-06-18',
    type: 'EP',
    length: '23:46',
    cover_front: 'Cvel_-_Cut_n_Waste_(Front).png',
    cover_back: 'Cvel_-_Cut_n_Waste_(Back).png',
    link_label: 'Coming soon',
    url: '',
    tracks: [
      'Strain',
      'Out of Order Lifestyle',
      'Extrovert',
      'Replacement for Joy',
      'Freeroaming',
      'The Slightest Inch',
      'Need To Be Me'
    ]
  },
  {
    title: 'Ambel',
    id: 'ambel',
    alias: 'cvel',
    date: '2005-01-01',
    type: 'Album',
    length: '36:11',
    cover_front: 'Cvel_-_Ambel_(Front).png',
    cover_back: 'Cvel_-_Ambel_(Back).png',
    url: 'https://cvel.bandcamp.com/album/ambel',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=3496149766/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=87853150/transparent=true/',
    tracks: [
      'Inverse Travel',
      'Nanobit',
      'Isen Flora',
      'Vintervy',
      'Cascade',
      'Thirtytwo',
      'Vhsyn Transfer',
      'Cell',
      'To Call my Own',
      'Perception of Distance',
      'Tiny Fingers'
    ],
    notes: "Originally released under the alias Sugarush. Tracks 1-4 and 6-10 were reworked in August 2023, the track order was also slightly changed.",
  },
  {
    title: 'Cosmic Arts',
    id: 'cosmic-arts',
    alias: 'cvel',
    date: '2003-08-31',
    type: 'Album',
    length: '34:37',
    cover_front: 'Cvel_-_Cosmic_Arts_(Front).png',
    cover_back: 'Cvel_-_Cosmic_Arts_(Back).png',
    url: 'https://cvel.bandcamp.com/album/cosmic-arts',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=1576043714/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=2627798468/transparent=true/',
    tracks: [
      'Clatter on a Window',
      'Thin Meets Pale',
      'Gelatin',
      'Passiv',
      'Tress',
      '4.07',
      'Facette Eyes',
      'The Rotation',
      'Morgonsol'
    ],
    notes: "All tracks were reworked and remastered in December 2022, the track order was also slightly changed.",
  },
  {
    title: 'Fast Collider',
    id: 'fast-collider',
    alias: 'cvel',
    date: '2003-06-01',
    type: 'Album',
    length: '40:46',
    cover_front: 'Cvel_-_Fast_Collider_(Front).png',
    cover_back: 'Cvel_-_Fast_Collider_(Back).png',
    url: 'https://cvel.bandcamp.com/album/fast-collider',
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=3184794953/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=1050648305/transparent=true/',
    tracks: [
      'Kinetic Siamese',
      'Signal Out',
      'Pine',
      'Falling Forward',
      'Sunrays',
      'Ageodiesel',
      'Nightwalk',
      'Figure of 8',
      'Good Morning World',
      'Ageodiesel (Kilowatts\' Mack Truck Remix)'
    ],
    notes: "This is a retroactive combination of two EPs: \"Coreolis EP 2\" (under the alias Sugarush) and \"Fast Collider\". All tracks were reworked and remastered in 2022. For legal reasons track 10 is a free bonus track.",
  },
  {
    title: 'Dvala',
    id: 'dvala',
    alias: 'cvel',
    date: '2002-11-11',
    type: 'Album',
    length: '39:51',
    cover_front: 'Cvel_-_Dvala_(Front).png',
    cover_back: 'Cvel_-_Dvala_(Back).png',
    url: 'https://cvel.bandcamp.com/album/dvala',
    tracks: [
      'Anemone',
      'Void',
      'Blinds',
      'Snow Field',
      'Morning Dew',
      'Sulphur',
      'White Marble',
      'Light Bulb',
      'Erosion',
      'Rhinestone',
      'Shapes',
      'Patchwork'
    ],
    bandcamp_embed: 'https://bandcamp.com/EmbeddedPlayer/album=2668894280/size=large/bgcol=333333/linkcol=e9be89/tracklist=false/artwork=small/track=1102435430/transparent=true/',
    notes: "Originally released under the alias Ubahn. Some tracks were reworked and remastered in 2022.",
  },
]