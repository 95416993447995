import TextBox from '../components/TextBox'
import { formatDate } from '../utils/date'
import Clock from '../font-awesome/Clock'

import styled from 'styled-components'

const Header = styled.h2`
  font-weight: 600;
  letter-spacing: -0.03em;
  margin-top: 4px;
`

const NewsPost = ({ header, date, children }) => {
  return (
    <TextBox>
      <div className="date"><Clock width={13} height={13} /> Posted {formatDate(date)}</div>
      <Header>{header}</Header>
      {children}
    </TextBox>
  )
}

export default NewsPost