import styled from 'styled-components'

const Wrapper = styled.div`
  display: block;
  color: #000;
  background-color: #fff;
  width: 46px;
  height: 46px;
  line-height: 22px;
  border-radius: 100%;
  font-size: 26px;
  font-family: inherit;
  font-weight: 800;
  white-space: nowrap;
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transition: opacity 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;

  &.visible {
    opacity: 0.9;
  }

  @media (min-width: 720px) {
    bottom: 30px;
    right: 30px;
  }
`

const BackToTop = ({className}) => {

  const goToTop = (e) => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
  }

  return (
    <Wrapper className={className} onClick={(e) => goToTop(e)}>
      &#8593;
    </Wrapper>
  )
}

export default BackToTop