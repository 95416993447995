import { ReleasesList } from '../data/arrangement-releases'
import { Arrangements } from '../data/arrangements'

import ReleaseViewer from '../components/ReleaseViewer'
import Arrangement from '../components/Arrangement'

import { useParams } from 'react-router-dom'

const Arranged = () => {
  const { id } = useParams()

  return (
    <div>
      <h1>Arranged game music</h1>
      <ReleaseViewer releases={ReleasesList} basePath="arranged" backLabel="Browse more arrangements" />

      {!id &&
        <>
          <div className="vspace" />

          <h2 className="section">Miscellaneous tracks</h2>
          {Arrangements.map((props) => {
            return (
              <Arrangement
                key={props.title}
                {...props}
              />
            )
          })}
        </>
      }
    </div>
  )
}

export default Arranged