export const Arrangements = [
  {
    title: 'Sinister',
    game: 'Doom',
    composer: 'Bobby Prince',
    date: '2023-12-10',
    youtube_link: 'https://www.youtube.com/watch?v=aPazFMLI3Mg',
  },
  {
    title: 'Waiting for Romero to Play',
    game: 'Doom II: Hell on Earth',
    composer: 'Bobby Prince',
    date: '2023-11-27',
    youtube_link: 'https://www.youtube.com/watch?v=kD_rVsDEgMg',
  },
  {
    title: 'Sign of Evil',
    game: 'Doom',
    composer: 'Bobby Prince',
    date: '2023-10-17',
    youtube_link: 'https://www.youtube.com/watch?v=6u_9JrDQUWU',
  },
  {
    title: 'Running from Evil',
    game: 'Doom II: Hell on Earth',
    composer: 'Bobby Prince',
    date: '2023-08-24',
    youtube_link: 'https://www.youtube.com/watch?v=mXOrB50P-Mc',
  },
  {
    title: 'The Dave D. Taylor Blues',
    game: 'Doom II: Hell on Earth',
    composer: 'Bobby Prince',
    date: '2023-07-16',
    youtube_link: 'https://www.youtube.com/watch?v=yLX3L7S5GEI',
  },
  {
    title: 'Hiding the Secrets',
    game: 'Doom',
    composer: 'Bobby Prince',
    date: '2023-06-06',
    youtube_link: 'https://www.youtube.com/watch?v=FWOgCxqHWks',
  },
  {
    title: 'Dark Halls',
    game: 'Doom',
    composer: 'Bobby Prince',
    date: '2023-04-20',
    youtube_link: 'https://www.youtube.com/watch?v=BC-k3hQ3vsU',
  },
  {
    title: 'The Demons From Adrian\'s Pen',
    game: 'Doom',
    composer: 'Bobby Prince',
    date: '2023-04-07',
    youtube_link: 'https://www.youtube.com/watch?v=YyiEVB48xRY',
  },
  {
    title: 'Donna to the Rescue',
    game: 'Doom',
    composer: 'Bobby Prince',
    date: '2023-03-23',
    youtube_link: 'https://www.youtube.com/watch?v=mhlKn5NaQ7I',
  },
  {
    title: 'Into Sandy\'s City',
    game: 'Doom II: Hell on Earth',
    composer: 'Bobby Prince',
    date: '2023-02-26',
    youtube_link: 'https://www.youtube.com/watch?v=LoFtn1Dsbkc',
  },
  {
    title: 'Sector 1 (SRX)',
    game: 'Metroid Fusion',
    composer: 'Minako Hamano',
    date: '2021-11-24',
    youtube_link: 'https://www.youtube.com/watch?v=PsBLMRGyHbA',
  },
  {
    title: 'Ice Scream (Planet Freon/Round 5-2)',
    game: 'Ristar',
    composer: 'Tomoko Sasaki',
    date: '2021-10-10',
    youtube_link: 'https://www.youtube.com/watch?v=4Bk2k6zGfKc',
  },
  {
    title: '3-Prayers',
    game: 'Alien Soldier',
    composer: 'Norio "NON" Hanzawa',
    date: '2021-08-29',
    youtube_link: 'https://www.youtube.com/watch?v=6ggiNLSliC0',
  },
  {
    title: 'Headquarters (Stage 3)',
    game: 'Journey to Silius',
    composer: 'Naoki Kodaka',
    date: '2020-12-27',
    youtube_link: 'https://www.youtube.com/watch?v=8UXSN4WQyuQ',
  },
  {
    title: 'Gravity Man',
    game: 'Mega Man 5',
    composer: 'Mari Yamaguchi',
    date: '2020-12-05',
    youtube_link: 'https://www.youtube.com/watch?v=l1AYYO4IPx0',
  },
  {
    title: 'Crossing the Sea of Clouds',
    game: 'Detana!! Twinbee',
    composer: 'Konami Kukeiha Club',
    date: '2020-11-14',
    youtube_link: 'https://www.youtube.com/watch?v=XUEk2-7IJpE',
  },
  {
    title: 'Searching for Friends',
    game: 'Final Fantasy VI',
    composer: 'Nobuo Uematsu',
    date: '2020-10-04',
    youtube_link: 'https://www.youtube.com/watch?v=j5Xwzz3hrwk',
  },
  {
    title: 'Theme of Seven Force',
    game: 'Gunstar Heroes',
    composer: 'Norio "NON" Hanzawa',
    date: '2020-09-05',
    youtube_link: 'https://www.youtube.com/watch?v=Si253HVO_08',
  },
  {
    title: 'Cold Man',
    game: 'Mega Man & Bass',
    composer: 'Akari Kaida',
    date: '2020-08-15',
    youtube_link: 'https://www.youtube.com/watch?v=hkSOV5Fz1JU',
  },
  {
    title: 'Heavy Metal Corridor (Level 3)',
    game: 'R-Type III',
    composer: 'Ikuko Mimori',
    date: '2020-08-01',
    youtube_link: 'https://www.youtube.com/watch?v=LMeMj89pq1Q',
  },
  {
    title: 'Acid Creature (Level 2)',
    game: 'R-Type III',
    composer: 'Ikuko Mimori',
    date: '2020-07-17',
    youtube_link: 'https://www.youtube.com/watch?v=aGOV_HthnOs',
  },
  {
    title: '!!!...Shade...',
    game: 'Alien Soldier',
    composer: 'Norio "NON" Hanzawa',
    date: '2020-06-15',
    youtube_link: 'https://www.youtube.com/watch?v=lhtZiZeUUsA',
  },
  {
    title: 'Kingdom of Devotindos',
    game: 'Rocket Knight Adventures',
    composer: 'Konami Kukeiha Club',
    date: '2020-03-08',
    youtube_link: 'https://www.youtube.com/watch?v=ashZDJuzoco',
  },
  {
    title: 'Heart of Icegrave',
    game: 'Monster World IV',
    composer: 'Jin Watanabe',
    date: '2020-02-16',
    youtube_link: 'https://www.youtube.com/watch?v=xZP3tGeAtUI',
  },
  {
    title: 'Hebe in the Sky',
    game: 'Hebereke/Ufouria: The Saga',
    composer: 'Naoki Kodaka',
    date: '2020-02-09',
    youtube_link: 'https://www.youtube.com/watch?v=DtA7lsAMSVk',
  },
  {
    title: 'Main Theme (Rapadanga City)',
    game: 'Monster World IV',
    composer: 'Jin Watanabe',
    date: '2020-01-26',
    youtube_link: 'https://www.youtube.com/watch?v=-1-CEygCSeY',
  },
  {
    title: 'The Sky Line (Air Raid)',
    game: 'Thunder Force IV',
    composer: 'Takeshi Yoshida & Toshiharu Yamanishi',
    date: '2020-01-05',
    youtube_link: 'https://www.youtube.com/watch?v=arVe4at69Zs',
  },
  {
    title: 'Immigration Ship L-229',
    game: 'Blaster Master Zero 2',
    composer: 'Hiroaki Sano',
    date: '2019-12-01',
    youtube_link: 'https://www.youtube.com/watch?v=aJOx5-rvYpw',
  },
  {
    title: 'Galaxy Desert',
    game: 'Alien Soldier',
    composer: 'Norio "NON" Hanzawa',
    date: '2019-10-09',
    youtube_link: 'https://www.youtube.com/watch?v=e9EDJ3zZgGY',
  },
  {
    title: 'The Foggy Cave in the Darkness',
    game: 'Contra: Hard Corps',
    composer: 'Konami Kukeiha Club',
    date: '2019-07-09',
    youtube_link: 'https://www.youtube.com/watch?v=8D8FIyKSDGo',
  },
  {
    title: 'Next Level',
    game: 'Blaster Master Zero',
    composer: 'Hiroaki Sano',
    date: '2019-05-11',
    youtube_link: 'https://www.youtube.com/watch?v=aXgbyYLuAjY',
  },
  {
    title: 'Top Man',
    game: 'Mega Man 3',
    composer: 'Yasuaki Fujita',
    date: '2019-02-28',
    youtube_link: 'https://www.youtube.com/watch?v=fMCxi31-h8w',
  },
  {
    title: 'Soltype',
    game: 'Alien Soldier',
    composer: 'Norio "NON" Hanzawa',
    date: '2019-02-10',
    youtube_link: 'https://www.youtube.com/watch?v=xfz-oQetIec',
  },
  {
    title: 'Good Weather',
    game: 'Gimmick!',
    composer: 'Masashi Kageyama',
    date: '2019-02-01',
    youtube_link: 'https://www.youtube.com/watch?v=ayEXBrjgPoI',
  },
  {
    title: 'Feel Pleasure to the Death',
    game: 'Gofer no Yabō Episode II/Nemesis 3',
    composer: 'Konami Kukeiha Club',
    date: '2019-01-13',
    youtube_link: 'https://www.youtube.com/watch?v=_K4eSWrnN5Y',
  },
  {
    title: 'Memories of Times Past (Robot Museum)',
    game: 'Mega Man & Bass',
    date: '2011-08-29',
    youtube_link: 'https://www.youtube.com/watch?v=Wl0pJCOiGqo',
  },
  {
    title: 'Underground Flora (Upper Brinstar)',
    game: 'Super Metroid',
    composer: 'Kenji Yamamoto',
    date: '2011-08-21',
    youtube_link: 'https://www.youtube.com/watch?v=ej_xg7qWwO4',
  },
  {
    title: 'Surface of SR-388 (Main theme)',
    game: 'Metroid II: Return of Samus',
    composer: 'Ryoji Yoshitomi',
    date: '2011-08-08',
    youtube_link: 'https://www.youtube.com/watch?v=Po_uvBjytlY',
  },
  {
    title: 'Space Odyssey (Gradius II Medley)',
    game: 'Gradius II / Vulcan Venture',
    composer: 'Konami Kukeiha Club',
    date: '2011-07-20',
    youtube_link: 'https://www.youtube.com/watch?v=6pHciO5hR94',
  },
  {
    title: 'Battle of the Holy (Stage 1)',
    game: 'The Castlevania Adventure',
    date: '2010-02-01',
    youtube_link: 'https://www.youtube.com/watch?v=_hZ7uEJqPiQ',
  },
  {
    title: 'Spring Yard Swing',
    game: 'Sonic The Hedgehog',
    composer: 'Masato Nakamura',
    date: '2009-12-06',
    youtube_link: 'https://www.youtube.com/watch?v=3BgaoTyms1c',
  }
]