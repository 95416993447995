import styled from 'styled-components'

import LinkButton from '../components/LinkButton'
import Play from '../font-awesome/Play'
import { formatDate } from '../utils/date'
import Clock from '../font-awesome/Clock'

const Wrapper = styled.div`
  background-color: #222;
  margin-bottom: 12px;
  
  &:last-child {
    margin-bottom: 0;
  }
`

const Info = styled.div`
  background-color: #222;
  padding: 14px 15px;
  line-height: 22px;
  font-size: 13px;

  @media (min-width: 720px) {
    font-size: 14px;
    line-height: 23px;
  }
`

const Date = styled.span`
  color: #bbb;
  white-space: nowrap;
  margin-left: 18px;

  svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 2px;
  }
`

const Playback = styled.div`
  background-color: #111;
  padding: 6px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 6px;
    }
  }
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`

const Title = styled.h3`
  margin: 0 0 5px 0;
  font-size: 16px;

  @media (min-width: 720px) {
    font-size: 17px;
  }
`

const InfoBlocks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`

const InfoBlock = styled.span`
  display: inline-block;

  @media (min-width: 720px) {
    &:after {
      content: '|';
      font-size: 17px;
      display: inline-block;
      margin: 0 10px;
      color: #888;
      vertical-align: middle;
    }

    &:last-child:after {
      content: '';
    }
  }
`

const Arrangement = ({ title, date, composer, game, youtube_link }) => {
  return (
    <Wrapper>
      <Info>
        <Top>
          <Title>{title}</Title>
          <Date><Clock /> {formatDate(date)}</Date>
        </Top>
        <InfoBlocks>
          <InfoBlock><strong>Game:</strong> {game}</InfoBlock>
          {composer && <InfoBlock><strong>Original composer:</strong> {composer}</InfoBlock>}
        </InfoBlocks>
      </Info>
      <Playback>
        <ul>
          <li><LinkButton to={youtube_link} target="_blank"><Play />&nbsp; Listen at YouTube</LinkButton></li>
        </ul>
      </Playback>
    </Wrapper>
  )
}

export default Arrangement