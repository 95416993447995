import { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'

import Header from './components/Header'
import Hero from './components/Hero'
import Footer from './components/Footer'
import BackToTop from './components/BackToTop'

import Home from './pages/Home'
import Music from './pages/Music'
import Arranged from './pages/Arranged'
import About from './pages/About'
import PageNotFound from './pages/PageNotFound'

import styled from 'styled-components'

const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  color: #fff;
`

function App() {
  const [offset, setOffset] = useState(0)
  const [currentPath, setCurrentPath] = useState()

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation()
  
    useEffect(() => {
      if(pathname !== currentPath) {
        window.scrollTo(0, 0)
        setCurrentPath(pathname)
      }
    }, [pathname])
  
    return null
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path='/' element={<Hero></Hero>} exact />
        </Routes>
        <Content>
          <div className="innerWrapper">
            <Routes>
              <Route path='*' element={<PageNotFound />} />
              <Route path='/' element={<Home />} exact />
              <Route path='/music' element={<Music />} />
              <Route path='/music/:id' element={<Music />} />
              <Route path='/arranged' element={<Arranged />} />
              <Route path='/arranged/:id' element={<Arranged />} />
              <Route path='/about' element={<About />} />
            </Routes>
          </div>
        </Content>

        <Footer />
      </div>
      <BackToTop className={offset >= 1000 && 'visible'} />
    </BrowserRouter>
  );
}

export default App;
