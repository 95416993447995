import TextBox from '../components/TextBox'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
      <h1>About myself</h1>
      <TextBox>
        <p>
          <em>I first got interested in creating music</em> in 1998 when I would record simple synth pop songs onto tape
          from my father's JVC NS-70 keyboard (a very rare keyboard from the mid 80's). The songs weren't very good but it got me into
          the basics of sound creation and sequencing.
        </p>
        <p>
          <em>A few years later I moved onto computers</em> and started making loop-based techno and drum &amp; bass. Again, these
          early attempts weren't very good but it was another opportunity to learn. Eventually I started to take things a little
          more seriously and turned to electronica and ambient, which I had been getting more and more interested in through the late 90's.
          After a while I began to self-release my music online under various aliases.
        </p>
        <p>
          Over the years I wanted to <em>explore more traditional or acoustic elements</em> to put in my music, such as guitars,
          piano and so on. This led to the making of the album <Link to="/music/on-a-golden-horizon">"On a Golden Horizon"</Link> which used
          more pop/rock song structures and less focus on repetition.
        </p>
        <p>
          <em>As a side-project I was making re-arrangements of video game music</em>, this became my primary focus for a couple of years.
          I've been a long-time enthusiast of video game soundtracks and saw this project as a way to step out of my comfort zone and learn
          how to reinterpret other people's compositions, especially those from early game consoles such as NES, Super Nintendo
          and Sega Mega Drive.
        </p>
        <p>
          <em>After about a 6 year long break from music making</em> I grew frustrated with not nurturing my passion, so I returned to
          arranging video game music. With brand new software tools and rekindled enthusiasm I quickly got back into the process. But there's
          only so much you can do relying on other people's compositions, I wanted to make my own songs again. So in the late autumn of 2020 I
          began preparing work on a new album, more ambitious than ever.
          This was during a very tough period as I was struggling with the loss of a family member. There was also the threat
          of a pandemic looming over everyone, so I thought a lot about the frailty of life and existence itself. The project became
          therapy for me and perhaps, at least in part, also a way to make some kind of statement. In the late summer of 2021 the
          album <Link to="/music/lifeshades">"Lifeshades"</Link> was ready after some difficult months of occasional creative block. This was
          followed up with the EP <Link to="/music/mending-days">"Mending Days"</Link> in 2022.
        </p>
        <p>
          <em>Artists that have influenced me</em> include Depeche Mode, M83, Kraftwerk, The Knife, Boards of Canada, Cocteau Twins, Radiohead, AIR and Aphex Twin.
        </p>
      </TextBox>
    </>
  )
}

export default About