import React, { useState, useEffect } from 'react'
import { useLocation, NavLink } from 'react-router-dom'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  font-family: 'IBM Plex Sans', sans-serif;

  @media (min-width: 720px) {
    padding: 8px 0 4px 0;
  }

  ul {
    display: block;
    transition: all 0.1s linear;
    position: relative;

    &.open {
      display: block;
    }

    @media (max-width: 720px) {
      display: none;
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 0;
      background-color: #000;
      width: 100%;
      padding: 0;

      li {
        display: block;

        a {
          padding: 14px 18px;
          display: block;
          width: 100%;

          @media (min-width: 720px) {
            display: inline-block;
            padding: 8px 18px;
          }
        }
      }
    }

    @media (min-width: 720px) {
      display: block;
      opacity: 1;
    }

    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-right: 35px;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;

      @media (min-width: 720px) {
        display: inline-block;
      }

      a {
        color: #ddd;
        text-decoration: none;
        font-weight: 600;
        border-bottom: 1px solid transparent;

        @media (min-width: 720px) {
          padding-top: 4px;
          padding-bottom: 4px;
          display: inline-block;
        }
      }

      a:hover {
        @media (min-width: 720px) {
          border-bottom: 1px solid #777;
        }
      }

      a.active {
        color: #fff !important;
        background-color: #303030;

        @media (min-width: 720px) {
          border-bottom: 1px solid #fff;
          background-color: transparent;
        }
      }
    }
  }
`

const MenuToggle = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media (min-width: 720px) {
    display: none;
  }
`

const ExternalLinks = styled.div`
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    height: 24px;
    margin-left: 18px;

    img {
      width: 26px;
      height: 26px;
    }
  }
`

const MenuIcon = styled.div`
  width: 100%;
  height: 4px;
  background-color: #fff;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -11px;
    width: 100%;
    height: 4px;
    background-color: #fff;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    width: 100%;
    height: 4px;
    background-color: #fff;
  }
`

function Nav() {
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const location = useLocation();

    useEffect(() => {
      setMenuIsOpen(false)
    }, [location])

    const handleMenuToggle = () => {
      setMenuIsOpen(!menuIsOpen)
    }

  return (
    <Wrapper>
        <MenuToggle onClick={() => handleMenuToggle()}><MenuIcon /></MenuToggle>
        <ul className={menuIsOpen ? 'open' : ''}>
          <li><NavLink to='/' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Home</NavLink></li>
          <li><NavLink to='/music' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Music</NavLink></li>
          <li><NavLink to='/arranged' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Arranged Game Music</NavLink></li>
          <li><NavLink to='/about' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>About</NavLink></li>
        </ul>
        <ExternalLinks>
          {/*
          <a href="https://soltype.bandcamp.com/" target="_blank" rel="noreferrer"><img src="/assets/images/icon_bandcamp.png" alt="Bandcamp" /></a>
          <a href="https://www.youtube.com/c/Gencoil" target="_blank" rel="noreferrer"><img src="/assets/images/icon_youtube.png" alt="YouTube" /></a>
          <a href="http://soundcloud.com/soltype" target="_blank" rel="noreferrer"><img src="/assets/images/icon_soundcloud.png" alt="Soundcloud" /></a>
          */}
        </ExternalLinks>
    </Wrapper>
  );
}

export default Nav;
