import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: rgba(0,0,0,0.25);
  margin-top: 18px;
  padding: 24px 2px 30px 2px;
  color: #ddd;
  font-size: 11px;
  line-height: 1.5em;

  @media (min-width: 720px) {
    font-size: 13px;
    text-align: left;
    line-height: 1.6em;
    margin-top: 38px;
    padding: 38px 0;
  }

  a {
    color: inherit;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
  }

  li img {
    vertical-align: middle;
    margin-right: 10px;
  }

  h3 {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #333;
  }
`

const Grid = styled.div`
  margin: 0 -8px;
  display: flex;
  flex-direction: row;

  > div {
    width: 100%;
    margin: 0 8px;
  }

  @media (min-width: 720px) {
    margin: 0 -12px;

    > div {
      margin: 0 12px;
    }
  }
`

const Spacer = styled.div`
  display: none;

  @media (min-width: 720px) {
    display: block;
  }
`

const Links = styled.ul`
  margin-bottom: 6px;

  img {
    height: 23px;
  }

`

const Footer = () => {
  return (
    <Wrapper>
      <div className='innerWrapper'>
      <Grid>
        <div>
          <h3>Credits</h3>
          All website contents copyright Tobias Franzén (with exception for music arrangements).<br /><br />
          This website uses icons from <a href="https://fontawesome.com/license/free" target="_blank" rel="noreferrer">Font Awesome</a>.
        </div>
        <div>
          <h3>Links</h3>
          <Links>
            <li><img src="/assets/images/icon_bandcamp.png" alt="Bandcamp" /><a href="https://soltype.bandcamp.com/" target="_blank" rel="noreferrer">Soltype</a></li>
            <li><img src="/assets/images/icon_bandcamp.png" alt="Soundcloud" /><a href="https://cvel.bandcamp.com/" target="_blank">Cvel</a></li>
            <li><img src="/assets/images/icon_youtube.png" alt="YouTube" /><a href="https://www.youtube.com/c/Gencoil" target="_blank">VGM Arrangements</a></li>
          </Links>
        </div>
        <Spacer>
          &nbsp;
        </Spacer>
      </Grid>
      </div>
    </Wrapper>
  )
}

export default Footer