import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import Release from '../components/Release'
import ReleaseItem from '../components/ReleaseItem'

import { useParams, useNavigate } from 'react-router-dom'

const Releases = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2,1fr);

  @media (min-width: 600px) {
    grid-template-columns: repeat(3,1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(4,1fr);
  }
`

const NotFound = styled.div`
  background-color: rgba(0,0,0,0.15);
  border: 1px solid rgba(255,255,255,0.15);
  padding: 16px 20px;
  margin-top: 20px;
  line-height: 1.6em;
`

const ReleaseViewer = ({releases, backLabel = 'Browse more releases', basePath}) => {
  const { id } = useParams()
  const [view, setView] = useState(id)
  const navigate = useNavigate()

  useEffect(() => {
    setView(id)
  }, [id])

  const goBack = (e) => {
    e.preventDefault()
    setView()
    navigate(`/${basePath}`)
  }

  const getRelease = (id) => {
    const releaseData = releases.filter(x => x.id === id)[0]
    if(releaseData) {
      return <Release {...releaseData} />
    }
    else {
      return <NotFound>Release not found.</NotFound>
    }
  }

  return (
    <div>
      {view ?
        <>
          <a href="/#" onClick={(e) => goBack(e)} className="button link desktop-only">{`< `}&nbsp;{backLabel}</a>
          {getRelease(view)}
        </>
      :
        <Releases>
          {releases.map(({ title, id, date, type, alias, cover_front }, index) => {
            return (
              <ReleaseItem
                key={id}
                title={title}
                id={id}
                date={date}
                type={type}
                alias={alias}
                cover_front={`${process.env.PUBLIC_URL}/assets/images/releases/${cover_front}`}
                zIndex={releases.length - index}
              />
            )
          })}
        </Releases>
      }
      {view && <a href="/#" onClick={(e) => goBack(e)} className="button link mobile-only" style={{ marginTop: '16px' }}>{`< `}&nbsp;{backLabel}</a>}
    </div>
  )
}

export default ReleaseViewer