import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled(Link)`
  line-height: 22px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  z-index: ${props => props.zindex};
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;

  &:hover .releaseInfo {
      background-color: #000;
  }

  .record {
    background-color: #000;
    width: 96%;
    height: 96%;
    margin: 2%;
    position: absolute;
    left: 0;
    z-index: 0;
    border: 1px solid #444;
    border-radius: 50%;
    transition: left 0.3s;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 60%);
  }

  &:hover .record {
    left: 25px;
  }
`

const ReleaseImageWrapper = styled.div`
  position: relative;
  padding-bottom: 100%;
  background-color: #000;
`

const ReleaseImage = styled.img`
  position: absolute;
  height: 100%;
  display: block;
  width: 100%;
  background-color: #999;
`

const ReleaseHeader = styled.div`
  background-color: rgba(0,0,0,0.55);
  padding: 7px 10px;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  margin-top: 1px;

  @media (min-width: 720px) {
    font-size: 14px;
  }
`

const ReleaseTitle = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 88%;
`

const Release = ({ id, title, cover_front, type, zIndex }) => {
  let basePath = window.location.href
  if(basePath.charAt(basePath.length-1) === '/') {
    basePath = basePath.slice(0, -1)
  }

  return (
    <Wrapper to={`${id}`} zindex={zIndex} >
      <ReleaseImageWrapper>
        {cover_front && cover_front.indexOf('undefined') === -1 && (
          <>
            <div className="record"></div>
            <ReleaseImage src={cover_front} />
          </>
        )}
      </ReleaseImageWrapper>
      <ReleaseHeader className="releaseInfo">
        <ReleaseTitle>{title}</ReleaseTitle><span>{type}</span>
      </ReleaseHeader>
    </Wrapper>
  )
}

export default Release